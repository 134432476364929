
/* Define custom animation */
@keyframes scale-up {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(1.2);
    }
  }
  
  /* Apply animation to logo */
  .animate-scale-up {
    animation: scale-up 1s forwards;
  }

  .animate-scale-down {
    animation: scale-up 2s backwards;
  }
  